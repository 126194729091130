@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

.BaseRow {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-height: var(--page-pad-plus);
  padding: var(--page-pad-half);
  border: var(--border-size) solid var(--color-slate-40);
}

.TableHeader {
  composes: BaseRow;
  background-color: var(--color-clay-20);
  border-bottom: none;
  align-items: center;
}

.TableRow {
  composes: BaseRow;
  border-top: none;
}

.TableFooter {
  composes: BaseRow;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-clay-20);
}

/*
  TableSpacer is used to visually separate sequential tables from each other as the
  elements returned in the JSX from the table components to the DynamicPage component
  cannot be wrapped in a parent element otherwise they will not split across pages 
*/
.TableSpacer {
  height: var(--page-pad-plus);
}

/*
  TableHorizontalBorder is used to create the appearance that the first table row which
  has been split onto a new page has a top border.
*/
.TableHorizontalBorder {
  height: var(--border-size);
  position: absolute;
  width: 100%;
  background-color: var(--color-grey6);
}
