@import '@hip/theme/src/css/sizing.css';

/*
  Root width values for table cells have been calculated by eye
  from designs. These components are primarily intended to be printed.
*/

:root {
  --price-cell-width: 12rem;
  --quantity-cell-width: 4rem;
}

.SimpleTableCell {
  display: flex;
  align-items: center;
  margin: 0;
}

.ProductNameCell {
  flex-grow: 1;
}

.QuantityCell {
  text-align: right;
  width: var(--quantity-cell-width);
}

.PriceCell {
  justify-content: flex-end;
  width: var(--price-cell-width);
}
