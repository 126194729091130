@import '@hip/theme/src/css/sizing';

.ProductStockGeneralError {
  font-size: 1.4rem;
  font-weight: bold;
  color: #d0021b;
  padding: var(--page-pad) var(--page-pad) var(--page-pad-half);
}

.ProductStockGeneralErrorIcon {
  display: inline-block;
  vertical-align: -0.8rem;
  margin-right: var(--page-pad);
  margin-left: var(--page-pad-quarter);
}
