.DropdownWrapper {
  position: relative;
}

.OptionsList {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  transform: translateY(100%);
  width: 100%;
}
