:root {
  --sizing-xxs: 0.1rem;
  --sizing-xs: 0.3rem;
  --sizing-sm: 0.5rem;
  --sizing-md: 0.75rem;
  --sizing-lg: 1rem;
  --sizing-xl: 2rem;
  --sizing-xxl: 3rem;

  --page-pad: 1rem;
  --page-pad-quarter: calc(var(--page-pad) / 4);
  --page-pad-half: calc(var(--page-pad) / 2);
  --page-pad-plus: calc(var(--page-pad) * 1.5);
  --page-pad-double: calc(var(--page-pad) * 2);
  --page-pad-double-plus: calc(var(--page-pad) * 2.5);
  --page-pad-tripple: calc(var(--page-pad) * 3);
  --page-pad-quarter-minus: calc(var(--page-pad-quarter) * -1);
  --page-pad-eighth: calc(var(--page-pad) / 8);

  --border-size: 0.1rem;
  --border-size-medium: 0.2rem;

  --icon-size-xxs: 1.2rem;
  --icon-size-xs: 1.6rem;
  --icon-size-s: 2.4rem;
  --icon-size-m: 3.6rem;
  --icon-size-l: 4.8rem;
  --icon-size-xl: 9.2rem;
  --icon-size-xxl: 12rem;

  --hammertime-size-s: 2rem;
  --hammertime-size-m: 6.4rem;
}
