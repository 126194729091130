@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/fonts.css';
@import '@hip/theme/src/css/media.css';

:root {
  --sub-total-width: 14.4rem;
  --sub-total-width-mobile: 10rem;
  --column-margin-right: 1.6rem;
}

.BomContentHeader {
  width: 100%;
  padding: var(--page-pad-plus) 0;

  @media (--media-tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
}

.ProductTitle {
  margin-bottom: var(--page-pad-double);
  @media (--media-tablet) {
    margin-right: var(--page-pad-double);
    margin-bottom: 0;
  }
}

.Icon {
  display: inline-block;
  margin-right: var(--page-pad-half);
  margin-bottom: var(--page-pad-quarter-minus);
}
