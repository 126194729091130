@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/media.css';

.Footer {
  background-color: var(--color-slate);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--sizing-md);

  @media (--media-tablet) {
    flex-direction: row;
    padding: var(--sizing-md) var(--sizing-xl);
  }
}

.Copyright {
  cursor: pointer;

  @media (--media-tablet) {
    margin-right: var(--page-pad-tripple);
  }
}

.FooterLinkList {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.FooterLinkItem {
  padding: var(--page-pad-half);
  &:not(:first-child) {
    padding-left: var(--page-pad-double);
  }
  @media (--media-tablet) {
    padding: 0 var(--page-pad-double);
    &:not(:first-child) {
      border-left: 1px solid var(--color-white);
    }
  }
}

.FooterLink {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
