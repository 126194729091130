/*
  Root width values for table cells have been calculated by eye
  from designs. These components are primarily intended to be printed.
*/

:root {
  --price-cell-width: 16rem;
  --quantity-cell-width: 4rem;
}

.ProductNameCell {
  flex-grow: 1;
  margin: 0;
}

.ProductQuantityCell {
  width: var(--quantity-cell-width);
  text-align: center;
  margin: 0;
}

.ProductPriceCell {
  width: var(--price-cell-width);
  text-align: right;
}

.ProductRowNoBorderBottom {
  border-bottom: none;
}

.BarcodeRow {
  justify-content: center;
}
