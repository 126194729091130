html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  border: 0;
  font-size: 62.5%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
