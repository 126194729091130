@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

:root {
  --header-height: 5rem;
  --mobile-menu-button-width: 5rem;
  --icon-wrapper-width: 4rem;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--header-height);
  justify-content: space-between;
}

.Logo {
  margin: 0 var(--sizing-lg);
}

.MobileMenuButtonWrapper {
  display: none;

  @media (--media-mobile) {
    display: block;
    height: 100%;
  }
}

.HeaderNav {
  display: flex;
  position: relative;

  @media (--media-mobile) {
    position: absolute;
    display: none;
    top: var(--header-height);
    left: 0;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - var(--header-height));
  }
}

.HeaderNav-MobileVisible {
  display: flex;
}

.HeaderNavItems {
  display: flex;
  list-style-type: none;
  background-color: var(--color-white);
  padding: 0;
  margin: 0;
  @media (--media-mobile) {
    display: block;
  }
}

.HeaderNavItem {
  padding: var(--page-pad-half);
  background-color: var(--color-slate);

  &:not(:last-child) {
    border-right: 1px solid var(--color-white);
  }

  &:hover {
    opacity: 0.8;
  }

  @media (--media-mobile) {
    border-right: none;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-white);
    }
  }
}

.HeaderNavItem-Primary {
  background-color: var(--color-primary);
  &:hover {
    opacity: 0.8;
  }
}

.HeaderNavItem-Disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.HeaderButton {
  padding: 0 var(--page-pad);

  @media (--media-mobile) {
    padding: 0;
  }
}

.MobileMenuOverlay {
  display: none;
  background-color: var(--color-black-20);
  flex-grow: 1;

  @media (--media-mobile) {
    display: block;
  }
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--page-pad-half);
  width: var(--icon-wrapper-width);

  @media (--media-tablet-only) {
    display: none;
  }
}
