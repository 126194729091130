@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

@import '../printedMissingProductsDetailedTable/printedMissingProductsDetailedTable.module.css';

/*
  Root width values for image width has been calculated by eye
  from designs. This component is primarily intended to be printed.
*/

:root {
  --image: 8rem;
}

.ProductThumb {
  flex-shrink: 0;
  width: var(--image);
  height: var(--image);

  img {
    width: var(--image);
    height: var(--image);
  }
}

.ProductDetails {
  flex-grow: 1;
  margin-left: var(--page-pad);
}

.ProductDetailsContent {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.ProductName {
  flex: 1;
}

.ProductNameText {
  margin-bottom: var(--page-quarter);
}

.ProductQuantity {
  width: var(--quantity-cell-width);
  text-align: center;
}

.ProductPrice {
  width: var(--price-cell-width);
  text-align: right;
}

.ProductPriceText {
  margin-bottom: var(--page-quarter);
}

.ProductCode {
  color: var(--color-slate-60Percent);
}

.ProductDiscontinued {
  margin: 0;
}
