@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/media.css';

.Icon {
  display: block;
  position: relative;
  flex-shrink: 0;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.Icon-size-xxs {
  width: var(--icon-size-xxs);
  height: var(--icon-size-xxs);
}

.Icon-size-xs {
  width: var(--icon-size-xs);
  height: var(--icon-size-xs);
}

.Icon-size-s {
  width: var(--icon-size-s);
  height: var(--icon-size-s);
}

.Icon-size-m {
  width: var(--icon-size-m);
  height: var(--icon-size-m);
}

.Icon-size-l {
  width: var(--icon-size-l);
  height: var(--icon-size-l);
}

.Icon-size-xl {
  width: var(--icon-size-xl);
  height: var(--icon-size-xl);
}

.Icon-size-xxl {
  width: var(--icon-size-xxl);
  height: var(--icon-size-xxl);
}

.Icon--isInline {
  display: inline-block;
  vertical-align: bottom;
}

.Icon--isFluid {
  display: block;
  width: 6rem;
  height: 6rem;

  svg {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.Icon--rPadding-s {
  margin-right: var(--page-pad-quarter);
}

.Icon--rPadding-m {
  margin-right: var(--page-pad-half);
}

.Icon--rPadding-l {
  margin-right: var(--page-pad);
}

.Icon--lPadding-s {
  margin-left: var(--page-pad-quarter);
}

.Icon--lPadding-m {
  margin-left: var(--page-pad-half);
}

.Icon--lPadding-l {
  margin-left: var(--page-pad);
}

.Icon-color-inherit {
  svg {
    fill: currentColor;
  }
}

.Icon-color-primaryDark {
  svg {
    fill: var(--color-icons-primaryDark);
  }
}

.Icon-color-secondaryDark {
  svg {
    fill: var(--color-text-secondaryDark);
  }
}

.Icon-color-primaryLight {
  svg {
    fill: var(--color-text-primaryLight);
  }
}

.Icon-color-darkGrey {
  svg {
    fill: var(--color-icons-darkGrey);
  }
}

.Icon-color-midGrey {
  svg {
    fill: var(--color-icons-midGrey);
  }
}

.Icon-color-grey {
  svg {
    fill: var(--color-icons-grey);
  }
}

.Icon-color-slate {
  svg {
    fill: var(--color-slate);
  }
}

.Icon-color-lightGrey {
  svg {
    fill: var(--color-icons-lightGrey);
  }
}

.Icon-color-white {
  svg {
    fill: var(--color-icons-white);
  }
}

.Icon-color-primaryBrand {
  svg {
    fill: var(--color-icons-primaryBrand);
  }
}

.Icon-color-primaryOpposite {
  svg {
    fill: var(--color-primaryOpposite);
  }
}

.Icon-color-secondaryOpposite {
  svg {
    fill: var(--color-secondaryOpposite);
  }
}

.Icon-color-tertiaryOpposite {
  svg {
    fill: var(--color-tertiaryOpposite);
  }
}

.Icon-color-negative {
  svg {
    fill: var(--color-icons-negative);
  }
}

.Icon-color-positive {
  svg {
    fill: var(--color-icons-positive);
  }
}

.Icon--isHeader {
  svg {
    fill: white;
  }
}

/* These two are used in places that are liable to change design very soon
   (lazy loading error widget and facets)
   Leaving them here for now */
.Icon--isHeaderNormal {
  composes: Icon--isHeader;
  width: 3.2rem;
  height: 3.2rem;
  transition: opacity 0.1s ease-out;
}

.Icon--isFilters-small {
  margin-top: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
}

.Icon--MastercardSecureCard {
  width: var(--mastercardSecureCard-icon-size);
  height: calc(var(--mastercardSecureCard-icon-size) / 3);
}

.Icon--VerifiedByVisa {
  width: var(--verifiedByVisa-icon-size);
  height: calc(var(--verifiedByVisa-icon-size) / 2);
}

.Icon--SecuredByPayPal {
  width: var(--securedByPayPal-icon-size);
  height: calc(var(--securedByPayPal-icon-size) / 3);
}

.Icon--LoyaltyCard {
  width: var(--loyaltyCard-icon-size);
  height: calc(var(--loyaltyCard-icon-size) / 1.6);

  svg {
    overflow: visible;
  }
}

.Icon--disabledPointer svg {
  pointer-events: none;
}

svg.Icon--CASTO {
  display: none;
  position: static;
  height: 2.4rem;

  @media (--media-tablet) {
    display: block;
  }

  @media (--media-large-desktop) {
    height: 3.2rem;
  }
}

svg.Icon--CASTO--mobile {
  position: static;
  height: 3.3rem;

  @media (--media-tablet) {
    display: none;
  }
}

svg.Icon--BDFR {
  @media (--media-desktop) {
    width: 8rem;
    height: 5rem;
  }
}

.Icon--logo--desktop {
  position: static;
  display: none;

  @media (--media-desktop) {
    display: block;
  }
}

.Icon--logo--tablet {
  position: static;
  display: none;

  @media (--media-tablet-only) {
    display: block;
  }
}

.Icon--logo--mobile {
  position: static;
  display: none;

  @media (--media-mobile) {
    display: block;
  }
}
