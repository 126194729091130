@import '@hip/theme/src/css/colors.css';

.QuoteErrorCard {
  background-color: var(--color-negative);
  color: var(--color-white);
}

.Title {
  margin: 0;
}

.SidebarHeaderIcon {
  margin-right: 1rem;
}
