@import '@hip/theme/src/css/colors.css';

.RegulatoryWarningCard {
  background-color: var(--color-graphics-negativeLight);
}

.AdvisoryWarningCard {
  background-color: var(--color-graphics-warningLight);
}

.Title {
  margin-bottom: 0;
}

.BulletList {
  list-style: none;
  padding: 0;
}

.SidebarHeaderIcon {
  margin-right: 1rem;
}
