@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/fonts.css';

.ProductWrapper {
  background-color: var(--color-white);
}

.ProductHeading {
  min-height: 5.5rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-grey12);
  padding: 0 var(--page-pad-double);
}

.ProductContent {
  margin: 0;
  padding: 0 var(--page-pad-double);
}
