@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

.Footer {
  margin-top: var(--page-pad-plus);
}

.FooterContent {
  display: flex;
  justify-content: space-between;
}

.Separator {
  margin-bottom: var(--page-pad);
  border-top: solid var(--border-size) var(--color-slate);
}
