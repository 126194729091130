@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

/*
  Root width values for image width has been calculated by eye
  from designs. This component is primarily intended to be printed.
*/

:root {
  --image: 8rem;
}

.Barcode {
  flex-shrink: 0;
  height: var(--image);

  svg {
    height: var(--image);
  }
}
