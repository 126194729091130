@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

.FeatureToggleListItem {
  margin-bottom: var(--page-pad);
}

.FeatureToggleListItemButtons {
  display: flex;
}

.FeatureToggleListItemButton {
  margin-right: var(--page-pad);
  color: var(--color-white);
}
.FeatureToggleListItemButton:disabled,
.FeatureToggleListItemButton[disabled] {
  opacity: 0.4;
}
