@import '@hip/theme/src/css/sizing';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/fonts.css';
@import '@hip/theme/src/css/media.css';

:root {
  --modal-header-height: 15.2rem;
  --modal-width: 120rem;
  --sidebar-width: 28rem;
}

.TitleWrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.Icon {
  margin-right: var(--page-pad-plus);
}

.ProductListWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-suede-light);
  overflow-y: scroll;
  height: 100vh;
}

.Header {
  display: flex;
  align-items: stretch;
  padding: 1rem;

  > i {
    margin-right: 1rem;
  }
}

.Container {
  padding: var(--page-pad-double);
}

.Columns {
  @media (--media-desktop) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.PlaceholderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ProductListContainer {
  width: 100%;
  overflow-y: auto;
}

.ProductList {
  > div:not(:last-child) {
    margin-bottom: var(--page-pad);
  }
}

.CategoryName {
  line-height: var(--page-pad-double);
  margin: var(--page-pad-double) 0;
}

.Sidebar {
  box-sizing: border-box;

  @media (--media-desktop) {
    margin-left: var(--page-pad);
  }

  @media (--media-desktop) {
    width: var(--sidebar-width);
    margin-left: var(--page-pad-double);
  }
}
