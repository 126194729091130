.MarxentClient {
  display: flex;
  width: 100%;
  flex: 15;
}

.MarxentClient iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}
