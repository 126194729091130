@font-face {
  font-family: 'GoodHome';
  src: local('Goodhome'),
    url('../assets/fonts/HIP-GoodHome-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoodHome-Bold';
  src: local('Goodhome-Bold'),
    url('../assets/fonts/HIP-GoodHome-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-family-sans-serif: 'GoodHome', -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, 'Helvetica Neue', arial, sans-serif;
  --font-family-monospace: 'GoodHome', sfmono-regular, menlo, monaco, consolas,
    'Liberation Mono', 'Courier New', monospace;
  --font-face-bold: 'GoodHome-Bold', -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, 'Helvetica Neue', arial, sans-serif;
  --font-size-xxs: 0.1rem;
  --font-size-xs: 0.3rem;
  --font-size-sm: 0.5rem;
  --font-size-md: 0.75rem;
  --font-size-lg: 1rem;
  --font-size-lg-plus: 1.25rem;
  --font-size-xl: 2rem;
  --font-size-xxl: 3rem;
}
