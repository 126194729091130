.App {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.Main,
.Wrapper {
  flex: 15;
  display: flex;
  width: 100vw;
  flex-direction: column;
}
