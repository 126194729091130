.ProductStockMessage {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: right;
}

.ProductStockMessageGreen {
  color: #00b050;
}

.ProductStockMessageRed {
  color: #c20b0b;
}

.ProductStockMessageAmber {
  color: #ff6600;
}
