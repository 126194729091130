@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

:root {
  --space: var(--page-pad-triple);
}

.Container {
  color: var(--color-slate);
  background-color: var(--color-clay-10);
}

.Header {
  display: flex;
  justify-content: space-between;
  min-height: calc(var(--page-pad-plus) + var(--page-pad-quadruple));
  background-color: var(--color-clay-20);
  padding: 0 var(--space);
}

.HeaderContent {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.HeaderIcon {
  display: flex;
  align-items: center;
  margin-left: var(--page-pad-double);
}

.Content {
  margin: var(--page-pad-double-plus) var(--space);
}
