@import '@hip/theme/src/css/sizing';

.Item {
  display: flex;
  margin: 0;
  padding-left: var(--page-pad);
  padding-bottom: var(--page-pad);
}

.BulletPoint {
  margin-right: var(--page-pad-plus);
}
