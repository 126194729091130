.Small {
  font-size: 60%;
}

.Super {
  line-height: 0;
  font-size: 60%;
  vertical-align: 0.45em;
}

.Price {
  font-weight: normal;
}

.Display {
  font-weight: bold;
}

.Size-s {
  font-size: 1rem;
}

.Size-m {
  font-size: 2rem;
}

.Size-l {
  font-size: 3rem;
}

.Size-xl {
  font-size: 4rem;
}
