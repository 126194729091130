@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/fonts.css';

.Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.Details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-clay-10);
  padding: var(--page-pad);
}

.DetailsInfo {
  display: grid;
  grid-gap: var(--page-pad);

  img {
    width: 100%;
  }
}

.Separator {
  border-top: var(--border-size) solid black;
}

.PriceText {
  margin: 0;
  display: inline-block;
}

.PriceTitle {
  font-family: var(--font-family-sans-serif);
}

.PriceAmount {
  font-family: var(--font-face-bold);
  margin-left: var(--page-pad-quarter);
}

.Info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Image {
  width: 100%;
  margin-left: var(--page-pad-half);
}
