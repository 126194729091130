.FullViewHeight {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ErrorWrapper {
  cursor: pointer;
}

.ErrorIcon {
  margin-bottom: 16px;
}
