@import '@hip/theme/src/css/fonts.css';

/* --a4-height is 296.4mm because a different value causes pages to not paginate correctly */
:root {
  --a4-width: 210mm;
  --a4-height: 296.4mm;
}

body {
  margin: 0;
  -webkit-print-color-adjust: exact;
  font-size: 62.5%;
}

.PrintDesignBomView {
  font-family: var(--font-family-sans-serif);
  font-size: 1.6rem;
  height: var(--a4-height);
  width: var(--a4-width);
}

@media screen {
  .PrintDesignBomView {
    width: 100%;
    height: 100%;
  }
}

.DynamicContentWrapper {
  position: absolute;
  width: 100%;
}

.CenterContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
}

@page {
  margin: 0;
}
