@import '@hip/theme/src/css/sizing';
@import '@hip/theme/src/css/media.css';

.OrderSummary {
  padding: var(--page-pad-double);
}

.InstallationRadioButtons {
  margin-bottom: var(--page-pad-half);
}

.SidebarOrderSummaryPrice {
  margin-bottom: var(--page-pad-plus);
}

.Title {
  margin-bottom: var(--page-pad-half);
}

.Installation {
  margin-bottom: var(--page-pad-half);
}

.InstallationNotice {
  margin-bottom: var(--page-pad-plus);
}

.Total {
  margin-bottom: var(--page-pad-quarter);
}

.Finance {
  text-decoration: underline;
  color: inherit;
}

.OrderPriceAndActions {
  display: flex;
  flex-direction: column;

  @media (--media-tablet-only) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.ButtonContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: var(--page-pad-double);

  @media (--media-tablet-only) {
    flex-direction: row;
    margin-top: 0;
  }
}

.Button {
  width: 100%;

  &:not(:last-child) {
    margin-top: var(--page-pad);
  }

  @media (--media-tablet-only) {
    width: auto;

    &:not(:last-child) {
      margin-top: 0;
    }

    &:not(:first-child) {
      margin-left: var(--page-pad-double-plus);
    }
  }
}
