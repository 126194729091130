@import '@hip/theme/src/css/sizing';
@import '@hip/theme/src/css/colors';

.Color-inherit {
  color: inherit;
}

.Color-slate {
  color: var(--color-slate);
}

.Color-white {
  color: var(--color-white);
}

.Size-xs {
  font-size: 1rem;
}

.Size-s {
  font-size: 1.2rem;
}

.Size-m {
  font-size: 1.4rem;
}

.Size-l {
  font-size: 1.6rem;
}

.Size-xl {
  font-size: 2rem;
}

.Size-xxl {
  font-size: 2.4rem;
}

.Size-xxxl {
  font-size: 3rem;
}

.Weight-normal {
  font-family: var(--font-family-sans-serif);
}

.Weight-bold {
  font-family: var(--font-face-bold);
}

.vMargin-none {
  margin-bottom: 0;
}

.vMargin-xs {
  margin-bottom: var(--page-pad-quarter);
}

.vMargin-s {
  margin-bottom: var(--page-pad-quarter);
}

.vMargin-m {
  margin-bottom: var(--page-pad-half);
}

.vMargin-l {
  margin-bottom: var(--page-pad);
}

.vMargin-xl {
  margin-bottom: var(--page-pad);
}

.vMargin-xxl {
  margin-bottom: var(--page-pad-plus);
}

.vMargin-xxxl {
  margin-bottom: var(--page-pad-double);
}
