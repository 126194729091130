.Fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.InvisibleLegend {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}
