.LoadAutoSaveModalRoot {
  max-width: 28rem;
  margin-top: auto;
  margin-bottom: auto;
}

.LoadAutoSaveModalHeader {
  padding-bottom: 0;
  align-items: start;
  border: none;
  line-height: 3.2rem;
}

.LoadAutoSaveModalHeader i {
  height: 2rem;
}

.LoadAutoSaveModalRadioGroup {
  margin-bottom: var(--page-pad-tripple);
}

.LoadAutoSaveModalButton {
  padding: 1.2rem 3.8rem;
  margin-bottom: 2.3rem;
}
