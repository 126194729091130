@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

:root {
  --hammertime-overlay-zindex: 900;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Hammertime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: var(--page-pad) auto;
}

.Hammertime--flat {
  padding: 0;
  margin: 0;
}

.Icon--size-m {
  width: var(--hammertime-size-m);
  height: var(--hammertime-size-m);
}

.Icon--size-s {
  width: var(--hammertime-size-s);
  height: var(--hammertime-size-s);
}

.Hammer-animate {
  animation: spinZ 1000ms infinite linear;
  transform-origin: center center;
}

@keyframes spinZ {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
