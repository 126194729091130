@import '@hip/theme/src/css/sizing.css';

:root {
  --image-height: 24rem;
  --button-width-form: 25rem;
}

.Placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: var(--page-pad-tripple);
  margin-bottom: var(--page-pad-tripple);
}

.Image {
  position: relative;
  transform: none;
  left: auto;
  top: 0;
  margin-bottom: 4rem;
  min-height: var(--image-height);
}

.SVGImage {
  margin-bottom: var(--page-pad-double);
}

.Title {
  margin: 0;
  margin-bottom: 0.5rem;
}

.Subtitle {
  max-width: 60rem;
  margin: 0;
  margin-bottom: 2rem;
}

.Button {
  max-width: none;
  min-width: var(--button-width-form);
  min-height: var(--page-pad-double-plus);
}
