@import '@hip/theme/src/theme.module.css';

:root {
  --color-primary: rgb(204, 229, 255);
  --color-success: rgb(212, 237, 218);
  --color-warning: rgb(255, 243, 205);
  --color-danger: rgb(251, 230, 229);
}

.Notification {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  padding: var(--page-pad);
}

.NotificationText {
  flex-grow: 1;
}

.Notification-primary {
  background-color: var(--color-primary);
}

.Notification-success {
  background-color: var(--color-success);
}

.Notification-warning {
  background-color: var(--color-warning);
}

.Notification-danger {
  background-color: var(--color-danger);
}

.Icon {
  margin-right: var(--page-pad-plus);
}
