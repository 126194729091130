@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

/* --a4-height is 296.4mm because a different value causes pages to not paginate correctly */
:root {
  --a4-width: 210mm;
  --a4-height: 296.4mm;
}

.Page {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10mm;
  height: var(--a4-height);
  width: var(--a4-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  page-break-after: always;
  overflow: hidden;
}

.PageContent {
  flex-grow: 1;
  min-height: 0;
  position: relative;
}

@media screen {
  .Page {
    margin: 2rem auto;
    border: var(--border-size-medium) var(--color-grey6) solid;
  }
}

@page {
  margin: 0;
}
