@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';

.Installation {
  width: 100%;
  display: grid;
  grid-gap: var(--page-pad);
  grid-template-columns: 1fr 1fr;

  img {
    border: var(--border-size-medium) var(--color-grey6) solid;
  }
}
