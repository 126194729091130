@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.CopyLinkControls {
  display: flex;
  align-items: stretch;
  margin-bottom: var(--page-pad);
}

.Input {
  flex-grow: 1;
  border: 1px solid var(--color-slate);
  background-color: var(--color-suede-light);
  padding: var(--page-pad-half);
  font-size: 1.2rem;
}

.ControlButton {
  margin-left: var(--page-pad);
}

.ShareButtonList {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.ShareButtonItem {
  margin-right: var(--page-pad);
}

.ShareButtonsWrapper {
  margin-top: var(--page-pad);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DropdownOptionItem {
  background-color: var(--color-suede-light);
}

.DropdownButton {
  width: 100%;
  &:hover {
    background-color: var(--color-white);
  }
}
