@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.MessageBase {
  display: flex;
  flex-direction: row;
}

.Message--padHalf {
  padding: var(--page-pad-half);
}

.Message--padFull {
  padding: var(--page-pad);
}

.Message--padFull .MessageIcon {
  margin-right: var(--page-pad);
}

.Message--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.MessageContentTitle {
  font-weight: 900;
  margin: 0;
}

.MessageContent {
  margin: 0;
}

.Message--isWarn {
  background: var(--color-graphics-warningLight);
}

.Message--isError {
  background: var(--color-graphics-negativeLight);
}

.Message--isErrorStark {
  background: var(--color-negative);
}

.Message--isSuccess {
  background: var(--color-graphics-positiveLight);
}

.Message--isSuccessStark {
  background: var(--color-jade);
}

.LearnMore {
  /* ensures that the link is not broken across two lines */
  white-space: nowrap;
  display: inline;
}
