@import '@hip/theme/src/css/sizing.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/media.css';

:root {
  --column-margin-right: 4rem;
  --image: 20rem;
  --image-desktop: 10rem;
  --pricing-width: 14.4rem;
}

.ProductListItem {
  padding: var(--page-pad-double) 0;
  align-items: flex-start;

  @media (--media-tablet) {
    display: flex;
  }
}

.PrimaryContent {
  display: flex;
  @media (--media-tablet) {
    flex: 0 0 70%;
    padding-right: var(--page-pad-double);
  }
}

.SecondaryContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: var(--page-pad);
  @media (--media-tablet) {
    margin-top: 0;
    flex: 0 1 30%;
  }
}

.ImageWrapper {
  width: var(--image-desktop);
  margin-right: var(--page-pad-double);
}

.Image {
  display: block;
  width: 100%;
}

.ProductPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.UnitPrice {
  display: flex;
}

.FlexBreak {
  flex-basis: 100%;
  height: 0;
}

.StockInformation {
  width: 100%;
  padding-top: var(--page-pad);
}
