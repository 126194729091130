.FlexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FlexCenter-FullHeight {
  height: 100%;
}
