@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

:root {
  --mobile-menu-button-width: 5rem;
}

.MobileMenuButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--mobile-menu-button-width);
  background-color: var(--color-slate);
  cursor: pointer;
  z-index: 3;

  .LineWrap {
    transition: all 600ms ease-in-out;

    @media (--media-tablet) {
      transition: none;
    }
  }

  .Line {
    width: 2.4rem;
    height: var(--sizing-xs);
    background-color: var(--color-white);
    transition: all 600ms ease-in-out;

    @media (--media-tablet) {
      transition: none;
    }
  }

  .Middle {
    margin: 0.4rem 0;
  }

  &.Active {
    .LineWrap {
      transform: rotate(-90deg);
    }

    .Middle {
      display: none;
    }

    .Top {
      width: var(--page-pad-tripple);
      transform-origin: 50% 50%;
      transform: rotate(45deg);
    }

    .Bottom {
      width: var(--page-pad-tripple);
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
    }
  }

  @media (--media-tablet) {
    display: none;
  }
}
