@import '@hip/theme/src/css/sizing';
@import '@hip/theme/src/css/colors.css';

.SidebarCard {
  background-color: var(--color-white);
  padding: var(--page-pad-double);

  > *:last-child {
    margin-bottom: 0;
  }
}

.SidebarCard:not(:last-child) {
  margin-bottom: var(--page-pad);
}
