.SmartLayout {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  font-size: 1.4rem;
}

.SmartLayoutWrapper {
  flex: 15;
  display: flex;
  width: 100vw;
  flex-direction: column;
}
