@import '@hip/theme/src/css/sizing';
@import '@hip/theme/src/css/colors.css';

.QuoteInfoCard {
  position: relative;
}

.QuoteID {
  background-color: var(--color-grey7);
  padding: var(--page-pad-half);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--page-pad);
}

.CopyQuoteIDButton {
  cursor: pointer;
}

.QuoteIDCopiedFlag {
  background-color: var(--color-grey7);
  position: absolute;
  right: var(--page-pad);
  top: var(--page-pad);
  padding: var(--page-pad-quarter) var(--page-pad-half);
}

.QuoteIDCopiedFlagPointer {
  width: 0.7rem;
  height: 0.7rem;
  background-color: var(--color-grey7);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) rotateZ(45deg);
}
