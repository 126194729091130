@import '@hip/theme/src/css/sizing.css';

.Radio {
  display: flex;
}

.Radio:focus-within .RadioIcon {
  /* TODO: I wanted to create some way we could abstract
   * these styles to a utility class - it seems this is
   * not possible with our CSS setup.
   * Mixins create new classes, that's not exactly what I
   * want, I'd prefer an inherritance model.
   * Composes can't be used either, as that can not be used
   * with psuedo classes. */

  /* Firefox / IE / Edge */
  outline: 1px dotted #212121;

  /* Chrome / Safari / Opera */
  outline: 0.4rem auto -webkit-focus-ring-color;
}

.RadioInput {
  /* Do not use 'display: none;' here
   * creates accessibility problems */
  opacity: 0;
  position: absolute;
}

.RadioGroupOption {
  margin-bottom: var(--page-pad-half);
}

.RadioGroupOption:last-child {
  margin-bottom: 0;
}
