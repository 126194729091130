@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.ModalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.Modal {
  margin-top: var(--page-pad-tripple);
  padding: var(--page-pad-double);
  background-color: var(--color-white);
  border-radius: var(--sizing-xs);
  max-width: 90%;
  cursor: initial;
}

.ModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border-size) solid var(--color-grey6);
  padding-bottom: var(--page-pad-double);
}

.ModalContent {
  padding-top: var(--page-pad-double);
}

.CloseButton {
  margin-left: var(--page-pad-tripple);
}
