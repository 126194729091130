@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

:root {
  --default-browser-outline-color: rgba(0, 103, 244, 0.247);
}

.Header {
  cursor: pointer;
  user-select: none;

  /*
    Bless me, Father, for I have sinned.

    This "beautiful" hack is required because onFocus gets called before onClick
    And there is absolutely no way to differentiate between onFocus caused by an
    onClick and one caused by any other method, like tabs

    What this does is delay by 0.1s the outline property styling from .HeaderFocus
    Just enough so that the onClick method is called and sets the "isFocused" back to false
  */
  transition: outline 0.0001s ease-in;
  transition-delay: 0.1s;
}

.Header.HeaderFocus {
  outline: var(--default-browser-outline-color) auto var(--page-pad-quarter);
}

.Content {
  animation: opacity 0.3s ease-in-out;
  opacity: 1;
}

.Content[hidden] {
  opacity: 0;
}
