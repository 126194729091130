:root {
  --width-BQUK-s: 2.4rem;
  --height-BQUK-s: 2.4rem;

  --width-BQUK-m: 3.6rem;
  --height-BQUK-m: 3.6rem;

  --width-BQUK-l: 4.8rem;
  --height-BQUK-l: 4.8rem;

  --width-BQUK-xl: 6.4rem;
  --height-BQUK-xl: 6.4rem;

  --width-BQUK-xxl: 8rem;
  --height-BQUK-xxl: 8rem;

  --width-BDRO-s: 3.96rem;
  --height-BDRO-s: 2.4rem;

  --width-BDRO-m: 5.94rem;
  --height-BDRO-m: 3.6rem;

  --width-BDRO-l: 7.92rem;
  --height-BDRO-l: 4.8rem;

  --width-BDRO-xl: 10.56rem;
  --height-BDRO-xl: 6.4rem;

  --width-BDRO-xxl: 13.2rem;
  --height-BDRO-xxl: 8rem;
}

.BrandIcon {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.IconWrapper {
  display: block;
}

.IconWrapper-BQUK-s {
  width: var(--width-BQUK-s);
  height: var(--height-BQUK-s);
}

.IconWrapper-BQUK-m {
  width: var(--width-BQUK-m);
  height: var(--height-BQUK-m);
}

.IconWrapper-BQUK-l {
  width: var(--width-BQUK-l);
  height: var(--height-BQUK-l);
}

.IconWrapper-BQUK-xl {
  width: var(--width-BQUK-xl);
  height: var(--height-BQUK-xl);
}

.IconWrapper-BQUK-xxl {
  width: var(--width-BQUK-xxl);
  height: var(--height-BQUK-xxl);
}

.IconWrapper-BDRO-s {
  width: var(--width-BDRO-s);
  height: var(--height-BDRO-s);
}

.IconWrapper-BDRO-m {
  width: var(--width-BDRO-m);
  height: var(--height-BDRO-m);
}

.IconWrapper-BDRO-l {
  width: var(--width-BDRO-l);
  height: var(--height-BDRO-l);
}

.IconWrapper-BDRO-xl {
  width: var(--width-BDRO-xl);
  height: var(--height-BDRO-xl);
}

.IconWrapper-BDRO-xxl {
  width: var(--width-BDRO-xxl);
  height: var(--height-BDRO-xxl);
}
